import classNames from "classnames";
import React from "react";

import { Box, IconButton, Typography } from "@mui/material";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { admin_report_path } from "../../../routes";

class AvatarAndDetail extends React.Component {
  state = { minWidth: 40 };
  render() {
    return (
      <div className="col s12 avatar-and-detail">
        <div
          className={`${
            this.props.list.length > 0 && "collection"
          } z-depth-1 white`}
          style={{ padding: `${this.props.hasTableheader && "20px"}` }}
        >
          <table className="table">
            {this.props.hasTableheader && (
              <thead>
                <tr className="collection-item border-bottom">
                  {this.props.hasAvatar && <th style={{ width: "60px" }}></th>}
                  <th
                    style={{
                      minWidth: `${this.state.minWidth}%`,
                      width: this.quantityOfCollumns() === 1 ? "90%" : "auto",
                    }}
                  >
                    {this.props.headers.first}
                  </th>
                  {this.props.hasSubAccount && (
                    <th style={{ width: this.widthCollumn() }}>Subconta</th>
                  )}
                  {this.props.hasName && (
                    <th style={{ width: this.widthCollumn() }}>
                      Nome do Cartão
                    </th>
                  )}
                  {this.props.hasUser && (
                    <th style={{ width: this.widthCollumn() }}>Usuário</th>
                  )}
                  {this.props.hasCard && (
                    <th style={{ width: this.widthCollumn() }}>Cartão</th>
                  )}
                  <th
                    style={{
                      width:
                        this.quantityOfCollumns() === 1
                          ? "20%"
                          : this.widthCollumn(),
                    }}
                  >
                    {this.props.headers.last}
                  </th>
                  {this.props.hasReceipt && (
                    <th style={{ width: this.widthCollumn() }}>Comprovante</th>
                  )}
                  {this.props.hasReportLink && (
                    <th style={{ width: this.widthCollumn() }}>Relatório</th>
                  )}
                </tr>
              </thead>
            )}
            <tbody>
              {this.props.list.map((item, idx) => (
                <tr
                  test-name={`collection-item-${item?.id}`}
                  key={idx}
                  className="collection-item"
                  onClick={() =>
                    window.location.assign(
                      this.props.baseLinkToDetail + item?.id
                    )
                  }
                  style={{
                    cursor:
                      this.props.baseLinkToDetail !== "#"
                        ? "pointer"
                        : "default",
                  }}
                >
                  {this.props.hasAvatar && (
                    <td
                      className="d-flex justify-center"
                      style={{ width: "60px" }}
                    >
                      {item?.avatar ? (
                        <img
                          className="avatar d-flex justify-center align-center circle"
                          src={item.avatar}
                        />
                      ) : (
                        <i
                          style={{ color: `${item.color_icon || ""}` }}
                          className={`avatar d-flex justify-center align-center circle mdi ${
                            item?.icon || "mdi-credit-card-off-outline"
                          } `}
                        />
                      )}
                    </td>
                  )}
                  <td
                    style={{
                      minWidth: `${this.state.minWidth}%`,
                      width: this.quantityOfCollumns() === 1 ? "90%" : "auto",
                    }}
                  >
                    <span className={`${item?.canceled ? "cancel" : ""}`}>
                      {item?.title}
                    </span>
                    <div className="d-flex">
                      {item?.description.length > 0 && (
                        <p
                          className={`${
                            item?.canceled ? "cancel-through" : ""
                          }`}
                        >
                          {item?.description}
                        </p>
                      )}
                      {item.tag && (
                        <p
                          style={{ color: `${item?.color_tag || ""}` }}
                          className={`${
                            item?.b_color_tag || "grey lighten-1"
                          } tag`}
                        >
                          {item.tag}
                        </p>
                      )}
                    </div>
                  </td>
                  {this.props.hasSubAccount && (
                    <td style={{ width: this.widthCollumn() }}>
                      {item?.sub_account}
                    </td>
                  )}
                  {this.props.hasName && (
                    <td style={{ width: this.widthCollumn() }}>{item?.name}</td>
                  )}
                  {this.props.hasUser && (
                    <td style={{ width: this.widthCollumn() }}>
                      {item?.user_name}
                    </td>
                  )}
                  {this.props.hasCard && (
                    <td style={{ width: this.widthCollumn() }}>{item?.card}</td>
                  )}
                  <td
                    style={{
                      width:
                        this.quantityOfCollumns() === 1
                          ? "20%"
                          : this.widthCollumn(),
                    }}
                    className={classNames({ cancel: item?.canceled })}
                  >
                    {item?.secondary}
                  </td>
                  {this.props.hasReceipt && (
                    <td
                      style={{ width: this.widthCollumn() }}
                      className="d-flex justify-center receipt"
                    >
                      {item?.receipt && (
                        <a href={item.receipt} target="blank">
                          <i className="mdi mdi-download" />
                        </a>
                      )}
                    </td>
                  )}
                  {this.props.hasReportLink && item.report_token && (
                    <td style={{ width: this.widthCollumn() }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            color: "black",
                            height: "auto",
                          }}
                        >
                          #{item.report_number}
                        </Typography>

                        <IconButton
                          sx={{ padding: "0 0 0 4px" }}
                          target="blank"
                          href={admin_report_path(item.report_token, {
                            expense: item.expense_id,
                          })}
                        >
                          <OpenInNewIcon
                            sx={{ color: "#3D0079" }}
                            color="primary"
                          />
                        </IconButton>
                      </Box>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  quantityOfCollumns() {
    return [
      this.props.hasSubAccount,
      this.props.hasName,
      this.props.hasUser,
      this.props.hasCard,
      true,
      this.props.hasReceipt,
      this.props.hasReportLink,
    ].filter((item) => item).length;
  }

  widthCollumn() {
    return `${(100 - this.state.minWidth) / this.quantityOfCollumns()}%`;
  }
}

AvatarAndDetail.defaultProps = {
  headers: { first: "Descrição", last: "Data" },
  hasTableheader: false,
  list: [],
  baseLinkToDetail: "#",
  hasAvatar: true,
  hasUser: false,
  hasSubAccount: false,
  hasName: false,
  hasCard: false,
  hasReceipt: false,
};

export default AvatarAndDetail;
