import {
  parserCNPJ,
  parserCPF,
  parserDate,
  parserHolderType,
  parserMainActivity,
  parserPhone,
  parser_resume_address,
} from "../../../shared/helpers";

export function parserSwapAccountObjectList(swap_account) {
  let section_list = [
    [
      { label: "Empresa", description: swap_account.company_name },
      {
        label: "Data de solicitação de abertura",
        description: parserDate(swap_account.created_at),
      },
      {
        label: "Data da última atualização",
        description: parserDate(swap_account.updated_at),
      },
      {
        label: "Treasury_account_id",
        description: swap_account.treasury_account_id,
      },
      {
        label: "Treasury_account_holder_id",
        description: swap_account.treasury_account_holder_id,
      },
      {
        label: "Treasury_account_status",
        description: swap_account.treasury_account_status,
      },
      {
        label: "Account_holder_id",
        description: swap_account.account_holder_id,
      },
      { label: "Status", description: swap_account.kyc_status },
      {
        label: "Análise de resultados",
        description: swap_account.kyc_analysis_results,
      },
    ],
  ];

  if (swap_account.legal_infos) {
    section_list.push([
      {
        label: "CNPJ",
        description: parserCNPJ(swap_account.legal_infos.cnpj),
      },
      {
        label: "Razão Social",
        description: swap_account.legal_infos.legal_name,
      },
      {
        label: "Data de fundação",
        description: parserDate(swap_account.legal_infos.founding_date),
      },
      {
        label: "Principal atividade",
        description: parserMainActivity(swap_account.legal_infos.main_activity),
      },
      {
        label: "Tipo de empresa",
        description: swap_account.legal_infos.business_type,
      },
      { label: "E-mail", description: swap_account.legal_infos.email },
      {
        label: "Telefone",
        description: parserPhone(swap_account.legal_infos.phone),
      },
      {
        label: "Endereço",
        description: parser_resume_address(
          swap_account.legal_infos.address.street,
          swap_account.legal_infos.address.number,
          swap_account.legal_infos.address.complement,
          swap_account.legal_infos.address.postal_code,
          swap_account.legal_infos.address.neighborhood,
          swap_account.legal_infos.address.city,
          swap_account.legal_infos.address.state
        ),
      },
    ]);
  }

  const holders = swap_account.holders.filter(
    (holder) => holder.holder_type === "physical"
  );

  if (holders.length > 0) {
    holders.forEach((holder, idx) => {
      section_list.push([
        { label: "CPF", description: parserCPF(holder.document) },
        { label: "Nome", description: holder.name },
        {
          label: "Data de Nascimento",
          description: parserDate(holder.birth_date),
        },
        {
          label: "Tipo de sócio",
          description: parserHolderType(holder.partner_type),
        },
      ]);
    });
  }

  return section_list;
}

export function parserSectionTittles(swap_account) {
  let section_tittles = ["Conta"];

  if (swap_account.legal_infos) {
    section_tittles.push("Dados da empresa");
  }

  const holders = swap_account.holders.filter(
    (holder) => holder.holder_type === "physical"
  );

  if (holders.length > 0) {
    holders.forEach((holder, idx) => {
      section_tittles.push(`Sócio PF (${idx + 1})`);
    });
  }

  return section_tittles;
}
