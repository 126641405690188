import React, { useState } from "react";

import Filter from "../shared/Filter";
import AvatarAndDetail from "../shared/lists/AvatarAndDetail";
import Loading from "../shared/Loading";
import Pagination from "../shared/Pagination";
import SelectTab from "../shared/SelectTab";

import filters, {
  types_without_incomes as filters_without_incomes,
} from "./filters";
import { checkTabActive } from "./helpers";

class StatementWithFilters extends React.Component {
  state = {
    showFilter: false,
    loading: false,
    forcePageOne: false,
    accountActivities: [],
    totalUnproven: 0,
    selectTab: "transactions",
    period: "",
    transaction_types: [],
    search: "",
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
      forcePageOne: !this.state.forcePageOne,
    });
  }

  render() {
    return (
      <React.Fragment>
        <SelectTab
          indicator={false}
          disabled={this.state.transaction_types.length > 0}
          list={this.listTab()}
          customList={[
            <div style={{ position: "absolute", right: 0, bottom: 0 }}>
              <div
                test-name="show-filters"
                className="btn-flat"
                onClick={() => {
                  this.setState({
                    ...this.state,
                    showFilter: !this.state.showFilter,
                  });
                }}
              >
                <i className="mdi mdi-filter-variant left" />
                <span className="hide-on-med-and-down">
                  FILTRAR VISUALIZAÇÃO
                </span>
              </div>
            </div>,
          ]}
        />
        <div
          test-name="div-show-filter"
          style={{ display: `${this.state.showFilter ? "block" : "none"}` }}
        >
          <Filter
            searchPlaceholder={"Busque por estabelecimento, valor ou subconta"}
            filters={
              this.props.show_incomes ? filters : filters_without_incomes
            }
            onFilter={(filters) => {
              this.setState({
                ...this.state,
                loading: true,
                forcePageOne: !this.state.forcePageOne,
                transaction_types: filters.selects?.transaction_types || [],
                ...filters,
              });
            }}
          />
        </div>
        {this.state.selectTab === "transactions" && this.renderTotalUnproven()}
        <Loading show={this.state.loading} />
        <AvatarAndDetail
          hasTableheader
          hasSubAccount
          hasUser
          hasReceipt={this.state.selectTab === "transfers"}
          hasReportLink={
            this.state.accountActivities.filter((e) => e.report_token).length >
            0
          }
          list={this.state.accountActivities.map((e, i) => ({
            ...e,
            title: e.name,
            description: e.cost,
            tag: (e.user_name !== "" || e.canceled) && e.label,
            color_tag: e.color_label?.color_label,
            b_color_tag: e.color_label?.b_color_label,
            secondary: e.date,
            sub_account: this.subAccountName(e),
            user_name: e.user_name,
            receipt: e.receipt_link,
          }))}
        />
        <Pagination
          perPage={20}
          forcePageOne={this.state.forcePageOne}
          url={this.props.endpoint}
          setExternalList={(list, others) =>
            this.setState({
              ...this.state,
              loading: false,
              accountActivities: list,
              totalUnproven: others.total_unproven,
            })
          }
          params={{
            filter: {
              scopes: this.state.transaction_types.length === 0 && [
                this.state.selectTab,
              ],
              period: this.state.period,
              transaction_types: this.state.transaction_types,
              store_sub_account_or_value: this.state.search,
            },
          }}
        />
      </React.Fragment>
    );
  }

  subAccountName(activity) {
    if (activity.sub_account_type === "subscription") {
      return "Fornecedores";
    }
    if (activity.sub_account_type === "banking_api") {
      return "Banking API";
    } else {
      return activity.sub_account_user_name;
    }
  }

  renderTotalUnproven() {
    return (
      <div
        test-name="total-unproven-purchase"
        style={{
          textAlign: "end",
          fontSize: "10px",
          textTransform: "uppercase",
        }}
      >
        {this.state.totalUnproven > 1
          ? this.state.totalUnproven + " despesas não comprovadas"
          : this.state.totalUnproven + " despesa não comprovada"}
      </div>
    );
  }

  handleTab(tab) {
    this.setState({
      ...this.state,
      selectTab: tab,
      loading: true,
      forcePageOne: !this.state.forcePageOne,
    });
  }

  listTab() {
    let list = [
      {
        testName: "transactions",
        href: "#transactions",
        label: "TRANSAÇÕES",
        onClick: () => {
          this.handleTab("transactions");
        },
        active: checkTabActive(
          "transactions",
          this.state.transaction_types,
          this.state.selectTab,
          filters
        ),
      },
      {
        testName: "incomes",
        href: "#incomes",
        label: "RENDIMENTOS",
        onClick: () => {
          this.handleTab("incomes");
        },
        active: checkTabActive(
          "incomes",
          this.state.transaction_types,
          this.state.selectTab,
          filters
        ),
      },
      {
        testName: "transfers",
        href: "#transfers",
        label: "RECARGAS E RESGATES",
        onClick: () => {
          this.handleTab("transfers");
        },
        active: checkTabActive(
          "transfers",
          this.state.transaction_types,
          this.state.selectTab,
          filters
        ),
      },
    ];

    if (!this.props.show_incomes) {
      list.splice(
        list.findIndex((e) => e.testName === "incomes"),
        1
      );
    }

    return list;
  }
}

StatementWithFilters.defaultProps = {
  endpoint: "",
  show_incomes: true,
  show_deposit_download: false,
};

export default StatementWithFilters;
