import {Alert, AlertTitle} from "@mui/material";
import React from "react";

export const SankhyaUserConfigSteps = () => {
    return <>
        <p><b>Passo 1: </b>Configuração no ERP</p>
        <ul>
            <li>
                <b>Acesse a tela "Dicionário de Dados"</b>
                para acessar vá até Configurações > Avançado > Dicionário de Dados.
            </li>
            <li>
                Em “Filtros rápidos” no campo de tabela digite TGFPAR (tabela de Parceiros) e
                clique em "Aplicar".
            </li>
            <li>
                Acesse o formulário da tabela de Parceiros.
            </li>
        </ul>
        <p><b>Passo 2: </b>Campos personalizados</p>
        <ul>
            <li>Na Aba "Campos", clique em Novo [+].</li>
            <li>
                <b>Nome do campo: </b>
                pode ser um campo ou tag já existente, sugerimos que seja utilizado o nome
                “Espresso”.
            </li>
            <li>
                <b>Descrição do campo: </b>
                é o nome que vai identificar o campo durante o uso no sistema, sugerimos que
                seja utilizado “Integra no Espresso”.
            </li>
        </ul>
        <Alert severity="warning">
            <AlertTitle>Tipo de dados</AlertTitle>
            Atenção, é necessário definir o campo “Tipo de dados” como “Texto”.
        </Alert>
        <ul>
            <li>
                <b>Apresentação:</b> no campo apresentação defina como “Checkbox”.
            </li>
            <li>
                <b>Permite pesquisa? </b>
                marque essa opção para permitir a pesquisa dos parceiros pelo Espresso.
            </li>
        </ul>
        <p><b>Passo 3: </b>Salvar os campos personalizados</p>
        <ul>
            <li>Clique em “✔” para salvar;</li>
        </ul>
        <p><b>Passo 4: </b>Deixar o campo personalizável visível no grid de pesquisa</p>
        <ul>
            <li>
                Ative a opção "Visível no grid de pesquisa?" para que o campo seja exibido na grade
                de resultados da pesquisa.    
            </li>
        </ul>
        <p><b>Passo 5: </b>Configurar a exibição do campo criado na tela de "Parceiros".</p>
        <ul>
            <li>
                Acesse a tela "Parceiros": para acessar vá até Configurações > Cadastro > Parceiros  
            </li>
            <li>
                Clique em "mostrar grade".
            </li>
            <li>
                No canto superior direito clique na engrenagem "⚙️".
            </li>
            <li>
                Em "campos disponíveis", pesquise pela descrição do campo, se você utilizou a nosso sugestão
                busque por “Integra no Espresso”.
            </li>
            <li>
                Com "painel principal" selecionado, arraste o campo.
            </li>
            <li>
                Clique em “✔”, para salvar.
            </li>
        </ul>
        <Alert severity="info">
            A partir dessa configuração, ao criar ou editar parceiros, será possível definir o que será integrado
            no Espresso, simplemente marcando o checkbox no novo campo adicionado.
        </Alert>
    </>
}
