import * as React from "react";
import {
  Badge,
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SvgIcon,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = 240;

export const AppbarOffset = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export function NavigationDrawer({open, children, onClose}) {
    const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return <Drawer
        open={open}
        variant={mobile ? "temporary" : "persistent"}
        PaperProps={{elevation: 1}}
        onClose={onClose}
        sx={{
            displayPrint: 'none',
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
        }}>
        <AppbarOffset/>
        <Box sx={{overflow: 'auto', pt: 2}}>
            {children}
        </Box>
    </Drawer>
}

export function DrawerSection({
  title,
  children,
  showDivider = true,
  collapse = false,
  open = false,
  icon,
}) {
  const [showCollapse, setShowCollapse] = React.useState(open);
  const collapseTittleStyle = {
    display: "flex",
    alignItems: "center",
    padding: "12px 0",
  };

  return (
    <React.Fragment>
      <List disablePadding>
        {title && (
          <ListSubheader
            /* istanbul ignore file */
            disableSticky
            onClick={() => {
              collapse && setShowCollapse(!showCollapse);
            }}
            style={{
              cursor: `${collapse && "pointer"}`,
              backgroundColor: `${
                showCollapse && !open ? "#F0EBF5" : "inherit"
              }`,
            }}
          >
            <Typography
              textTransform={"uppercase"}
              variant={"caption"}
              color={grey[600]}
              style={collapse ? collapseTittleStyle : {}}
            >
              {collapse && <div style={{ marginRight: "8px" }}>{icon}</div>}
              {title}
              {collapse && (
                <div style={{ marginLeft: "auto" }}>
                  {showCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              )}
            </Typography>
          </ListSubheader>
        )}
        {collapse ? (
          <Collapse in={showCollapse}>{children}</Collapse>
        ) : (
          children
        )}
      </List>
      {showDivider && <Divider className={"mb-2"} />}
    </React.Fragment>
  );
}

export function NavigationItem({label, icon, href, isNew = false}) {
    const regexp = new RegExp(escapeRegex(href));

    return <ListItem disablePadding>
        <ListItemButton href={href} selected={regexp.test(window.location.href)}>
            <ListItemIcon sx={{minWidth: 24}}>
                <SvgIcon fontSize={"small"}>{icon}</SvgIcon>
            </ListItemIcon>
            <ListItemText sx={{px: 1}}>
                <Typography variant={"body2"} color={grey[900]}>{label}</Typography>
            </ListItemText>
            {isNew && (
                <Badge badgeContent={"NOVO"} color={"primary"} sx={{mr: 2}}/>
            )}
        </ListItemButton>
    </ListItem>
}

export function NavigationSubItem({label, href, badgeContent}) {
    const regexp = new RegExp(escapeRegex(href));

    return <ListItem disablePadding dense>
        <ListItemButton href={href} selected={regexp.test(window.location.href)}>
            <ListItemText sx={{pl: 4}}>
                <Typography variant={"caption"} textTransform={'uppercase'} color={grey[600]}>
                    {label}
                </Typography>
            </ListItemText>
            <Badge badgeContent={badgeContent} color={"surfaceLight8"} sx={{mr: 1}}/>
        </ListItemButton>
    </ListItem>
}

const escapeRegex = (string) => {
    return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}