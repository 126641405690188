import React from "react";

import Loading from "../../shared/Loading";
import Pagination from "../../shared/Pagination";
import AvatarAndDetail from "../../shared/lists/AvatarAndDetail";
import SelectTab from "../../shared/SelectTab";
import Filter from "../../shared/Filter";

import { subscription_filters } from "../filters";
import { checkTabActive } from "../helpers";

class SubscriptionStatement extends React.Component {
  state = {
    forcePageOne: false,
    list: [],
    loading: true,
    transaction_types: [],
    selectTab: "transactions",
    showFilter: false,
  };

  componentDidMount() {
    this.setState({ forcePageOne: !this.state.forcePageOne });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.has_activities ? (
          <React.Fragment>
            <SelectTab
              indicator={false}
              list={this.listTab()}
              customList={[
                <div style={{ position: "absolute", right: 0, bottom: 0 }}>
                  <div
                    test-name="show-filters"
                    className="btn-flat"
                    onClick={() =>
                      this.setState({ showFilter: !this.state.showFilter })
                    }
                  >
                    <i className="mdi mdi-filter-variant left" />
                    <span className="hide-on-med-and-down">
                      FILTRAR VISUALIZAÇÃO
                    </span>
                  </div>
                </div>,
              ]}
            />
            <div
              test-name="div-show-filter"
              style={{ display: `${this.state.showFilter ? "block" : "none"}` }}
            >
              <Filter
                searchPlaceholder={"Busque por fornecedor, valor ou nome"}
                filters={subscription_filters}
                onFilter={(filters) => {
                  this.setState({
                    loading: true,
                    forcePageOne: !this.state.forcePageOne,
                    transaction_types: filters.selects?.transaction_types || [],
                    ...filters,
                  });
                }}
              />
            </div>
            <Loading show={this.state.loading} />
            <AvatarAndDetail
              hasTableheader
              hasName
              hasUser
              hasCard
              hasReportLink={
                this.state.list.filter((e) => e.report_token).length > 0
              }
              list={this.state.list.map((e) => ({
                ...e,
                title: e.name,
                description: e.cost,
                tag: (e.user_name !== "" || e.canceled) && e.label,
                color_tag: e.color_label?.color_label,
                b_color_tag: e.color_label?.b_color_label,
                secondary: e.date,
                name: e.card_description,
                card: e.card,
              }))}
            />
          </React.Fragment>
        ) : (
          <div className="collection z-depth-1 subscription-statement">
            <h6 className="collection-item">
              As despesas estão relacionadas ao uso do cartão.
              {!this.props.hasCards && " Ative agora mesmo o seu!"}
            </h6>
          </div>
        )}
        <Loading show={this.state.loading} />
        <Pagination
          url={this.props.endpoint}
          perPage={20}
          forcePageOne={this.state.forcePageOne}
          setExternalList={(list) => this.setState({ list, loading: false })}
          setExternalLoading={(state) => this.setState({ loading: state })}
          params={{
            filter: {
              scopes: this.state.transaction_types.length === 0 && [
                this.state.selectTab,
              ],
              period: this.state.period,
              transaction_types: this.state.transaction_types,
              store_sub_account_or_value: this.state.search,
            },
          }}
        />
      </React.Fragment>
    );
  }

  listTab() {
    return [
      {
        testName: "transactions",
        href: "#transactions",
        label: "TRANSAÇÕES",
        onClick: () => {
          this.handleTab("transactions");
        },
        active: checkTabActive(
          "transactions",
          this.state.transaction_types,
          this.state.selectTab,
          subscription_filters
        ),
      },
      {
        testName: "transfers",
        href: "#transfers",
        label: "RECARGAS E RESGATES",
        onClick: () => {
          this.handleTab("transfers");
        },
        active: checkTabActive(
          "transfers",
          this.state.transaction_types,
          this.state.selectTab,
          subscription_filters
        ),
      },
      {
        testName: "activities",
        href: "#activities",
        label: "ATIVIDADES",
        onClick: () => {
          this.handleTab("activities");
        },
        active: checkTabActive(
          "activities",
          this.state.transaction_types,
          this.state.selectTab,
          subscription_filters
        ),
      },
    ];
  }

  handleTab(tab) {
    this.setState({
      selectTab: tab,
      loading: true,
      forcePageOne: !this.state.forcePageOne,
    });
  }
}

SubscriptionStatement.defaultProps = {
  endpoint: "",
  has_activities: false,
  hasCards: false,
};

export default SubscriptionStatement;
